<template>
    <div class="new-user">
        <div class="contentbox">
            <div class="left-con">
                <div class="titlebox">
                    <div class="atitle">信息安全与隐私保护</div>
                    <div class="introduce">{{ base.tsYinsi }}</div>
                </div>
                <div class="tips">
                    <div class="tips-title">
                        <div class="icon">
                            <div class="picbox">
                                <div class="pic" style="background-image: url(/img/tips.png)"></div>
                            </div>
                        </div>
                        <div class="txt">小贴士</div>
                    </div>
                    <p class="tips-con" v-html="base.tsTieShi"/>
                </div>
            </div>
            <div class="right-con">
                <div class="titlebox">
                    <div class="atitle">新用户信息登记</div>
                </div>
                <div class="form">
                    <div class="form-item">
                        <label class="itemtitle" for="username">新用户名</label>
                        <input class="input" v-bind:class="{ 'active': tishi_sw == 'username' }" id="username" type="text"
                            maxlength="20" placeholder="3-20位的字母或数字" v-model="formInline.username" />
                    </div>
                    <div class="form-item">
                        <label class="itemtitle" for="password">您的密码</label>
                        <input class="input" v-bind:class="{ 'active': tishi_sw == 'password' }" id="password"
                            type="password" maxlength="20" placeholder="6-20位的字母+数字" v-model="formInline.password" />
                    </div>
                    <div class="form-item">
                        <label class="itemtitle" for="name">您的姓名</label>
                        <input class="input" v-bind:class="{ 'active': tishi_sw == 'name' }" id="name" type="text"
                            maxlength="20" placeholder="请输入您的姓名" v-model="formInline.name" />
                    </div>
                    <div class="form-item">
                        <div class="itemtitle">您的性别</div>
                        <div class="input" style="background: none;">
                            <div class="radio">
                                <input id="man" name="sex" checked type="radio" value="男" v-model="formInline.sex" />
                                <label for="man">男</label>
                            </div>
                            <div class="radio">
                                <input id="woman" name="sex" type="radio" value="女" v-model="formInline.sex" />
                                <label for="woman">女</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <label class="itemtitle" for="tel">联系电话</label>
                        <input class="input" v-bind:class="{ 'active': tishi_sw == 'tel' }" id="tel" type="text"
                            maxlength="11" placeholder="请输入您的联系方式" v-model="formInline.tel" />
                    </div>
                    <div class="form-item">
                        <label class="itemtitle" for="age">您的年龄</label>
                        <input class="input" v-bind:class="{ 'active': tishi_sw == 'age' }" id="age" type="text"
                            maxlength="3" placeholder="请输入您的年龄" v-model="formInline.age" />
                    </div>
                    <div class="form-item">
                        <div class="itemtitle2" style="flex: 1;">是否同意我院专业人员电话回访</div>
                    </div>
                    <div class="form-item">
                        <div class="input" style="margin-left: -3rem; background: none;">
                            <div class="radio">
                                <input id="yes" checked name="tongyi" type="radio" value="是"
                                    v-model="formInline.agree" />
                                <label for="yes">是</label>
                            </div>
                            <div class="radio">
                                <input id="no" name="tongyi" type="radio" value="否" v-model="formInline.agree" />
                                <label for="no">否</label>
                            </div>
                        </div>
                    </div>
                    <div class="detail">
                        <div class="itemtitle">
                            <div class="atitle">心理健康描述</div>
                            <div class="btitle">{{ formInline.detail.length }}/200</div>
                        </div>
                        <textarea v-model="formInline.detail" v-bind:class="{ 'active': tishi_sw == 'detail' }"
                            class="textarea" maxlength="200" placeholder="请简单描述一下您最近的心理状态，或者您有什么问题想要了解、咨询。"></textarea>
                    </div>
                    <div class="tips">{{ base.tsTongyi }}</div>
                    <div class="btns">
                        <button class="btn" @click="register" v-loading.fullscreen.lock="fullscreenLoading">提交信息</button>
                        <button class="btn" @click="back">返回登录</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getBase,zhuce } from "@/js/request";
import { ElMessage } from 'element-plus'

// @ is an alias to /src
// import { ElForm, ElFormItem, ElInput, ElRadioGroup, ElRadio } from 'element-plus'
export default {
    name: 'NewUser',
    components: {
        // ElForm,
        // ElFormItem,
        // ElInput,
        // ElRadioGroup,
        // ElRadio
    },
    data() {
        return {
            base: {},
            openid:'',
            formInline: {
                username: '',
                password: '',
                name: '',
                sex: '男',
                tel: '',
                age: '',
                agree: '是',
                detail: '',
            },

            tishi_sw: '',
            fullscreenLoading:false,

        }
    },
    methods: {
        back() {
            this.$router.replace('/home');
        },
        register() {
            var that = this;
            that.tishi_sw = '';
            if (that.formInline.username == '') {
                that.tishi_sw = 'username';
                return;
            } else if (that.formInline.password == '') {
                that.tishi_sw = 'password';
                return;
            } else if (that.formInline.name == '') {
                that.tishi_sw = 'name';
                return;
            } else if (that.formInline.tel == '') {
                that.tishi_sw = 'tel';
                return;
            } else if (that.formInline.age == '') {
                that.tishi_sw = 'age';
                return;
            } 
            // else if (that.formInline.detail == '') {
            //     that.tishi_sw = 'detail';
            //     return;
            // }
            that.fullscreenLoading = true; 
            zhuce({
                "method": 0, 
                "codeKey": "test",
                "codeValue": "test",
                "siteId": 1,
                "userName": that.formInline.username,
                "password": that.formInline.password,
                "realName": that.formInline.name,
                "phone": that.formInline.tel,
                "sex": that.formInline.sex,
                "age": that.formInline.age?that.formInline.age:0,
                "hui": that.formInline.agree,
                "remark": that.formInline.detail,
                "openid": that.openid,
            }).then(res => {
                that.fullscreenLoading = false
                console.log(res);
                that.errorts = '恭喜您注册成功';
                ElMessage({
                    message:that.errorts,
                    type: 'success',
                    "on-close":function(){
                        that.$router.replace('/home');
                    }
                })  
            }).catch(res => {
                that.fullscreenLoading = false
                if(Array.isArray(res.response.data.message)){
                    that.errorts=res.response.data.message[0][0];    
                }else{
                    that.errorts=res.response.data.message;    
                }        
                ElMessage({
                    message:that.errorts,
                    type: 'warning',                    
                })       
                console.log(res);                
            });
        }
    },
    mounted() {
        this.$root.showRight();
        if(this.$route.query.openid){
            this.openid=this.$route.query.openid;
        }
        getBase().then(res => {
            this.base = res.data;
        });

    }
}
</script>

<style lang="scss">
.new-user {
    @include sys-flex();
    width: 100vw;
    height: 100vh;

    .contentbox {
        @include sys-boxsize(1400px, 800px, 20px);
        box-shadow: 0px 0px 50px 0px rgba(63, 101, 224, 0.05);
        display: flex;
        overflow: hidden;

        .left-con,
        .right-con {
            box-sizing: border-box;
            height: 100%;
        }

        .left-con {
            width: 100%;
            @include sys-flex(space-between);
            flex: 1;
            padding: 60px;
            background: $acolor;

            .titlebox {
                text-align: left;
                line-height: 38px;
                max-height: 50%;
                overflow: hidden;
                width: 100%;

                .atitle {
                    @include sys-text-hide(1);
                    @include sys-font(36px, #fff, bold);
                    margin-bottom: 30px;
                }

                .introduce {
                    @include sys-font(20px, #fff);
                    text-indent: 40px;
                    letter-spacing: 1px;
                }
            }

            .tips {
                width: 100%;
                text-align: left;
                max-height: 50%;
                overflow: hidden;
                letter-spacing: 1px;

                .tips-title {
                    display: flex;
                    align-items: center;

                    .icon {
                        @include sys-picbox(24px, 24px);
                        margin-right: 10px;
                    }

                    .txt {
                        @include sys-text-hide(1);
                        @include sys-font(24px, #fff);
                        line-height: 26px;
                    }
                }

                .tips-con {
                    @include sys-font(16px, #fff);
                    width: 100%;
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    padding: 26px 30px;
                    margin-top: 20px;
                    box-sizing: border-box;

                    p {
                        margin-bottom: 14px;
                    }
                }
            }
        }

        .right-con {
            display: flex;
            flex-direction: column;
            flex: none;
            width: 820px;
            padding: 40px 60px;
            background: #fff;

            .titlebox {
                flex: none;
                text-align: center;

                .atitle {
                    @include sys-text-hide(1);
                    @include sys-font(36px, #000, bold);
                    line-height: 38px;
                    margin-bottom: 30px;
                }
            }

            .form {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 100%;
                overflow: hidden;

                .btns {
                    width: 100%;
                    @include sys-flex(space-between, row);

                    .btn {
                        @include sys-flex();
                        font-size: 20px;
                        margin-bottom: 20px;
                        cursor: pointer;

                        &:first-child {
                            @include sys-boxsize(340px, 60px, 6px, $acolor);
                            color: #fff;
                        }

                        &:last-child {
                            @include sys-boxsize(340px, 60px, 6px);
                            border: 2px solid $acolor;
                            color: $acolor;
                        }
                    }
                }

                .tips {
                    @include sys-text-hide(3);
                    @include sys-font(16px, #999);
                    line-height: 24px;
                    margin: 24px 0;
                }

                .detail {
                    width: 100%;

                    // .itemtitle:before{
                    //     content:'*';
                    //     color: red;
                    // }
                    .itemtitle {
                        @include sys-flex(space-between, row);
                        width: 100%;
                        line-height: 22px;
                        margin-bottom: 20px;

                        .atitle {
                            flex: 1;
                            @include sys-text-hide(1);
                            @include sys-font(20px, #000);
                        }

                        .btitle {
                            flex: none;
                            @include sys-text-hide(1);
                            @include sys-font(20px, #999);
                            letter-spacing: 0;
                        }
                    }

                    .textarea {
                        @include sys-font(20px, #000);
                        width: 100%;
                        height: 120px;
                        background: #F8FAFE;
                        border-radius: 6px;
                        padding: 15px 20px;
                        box-sizing: border-box;

                        &.active {
                            border: 1px solid #ff0000;
                        }
                    }
                }

                // .agree {
                //     display: flex;
                //     align-items: center;
                //     height: 40px;
                //     margin-bottom: 10px;
                //     @include sys-font(20px, #666);
                //     .input {
                //         @include sys-radius-btn(20px, 20px, #F8FAFE);
                //         margin-right: 10px;
                //         opacity: 0;
                //     }
                //     .itemtitle {
                //         position: relative;
                //         &::before {
                //             content: '';
                //             position: absolute;
                //             top: 50%;
                //             left: -30px;
                //             @include sys-radius-btn(20px, 20px, #F8FAFE);
                //             border: 2px solid #BCC3D8;
                //             transform: translateY(-50%);
                //             box-sizing: border-box;
                //         }
                //     }
                //     input:checked + .itemtitle::before {
                //         background: url(../assets/img/yes.png) no-repeat center center/contain;
                //         border: none;
                //     }
                // }
                .form-item {
                    @include sys-font(20px, #000);
                    @include sys-flex(space-between, row);
                    width: 325px;
                    height: 60px;
                    text-align: left;
                    margin-bottom: 15px;

                    .itemtitle:before{
                        content:'*';
                        color: red;
                    }
                    .itemtitle {
                        width: 105px;
                        line-height: 60px;
                        @include sys-text-hide(1);
                    }

                    .itemtitle2 {
                        width: 105px;
                        line-height: 60px;
                        @include sys-text-hide(1);
                    }

                    .input {
                        @include sys-font(20px, #000);
                        display: flex;
                        width: 220px;
                        height: 100%;
                        padding: 0 20px;
                        box-sizing: border-box;
                        background: #F8FAFE;
                        border-radius: 6px;

                        &.active {
                            border: 1px solid #ff0000;
                        }

                        .radio {
                            @include sys-flex(space-between, row);
                            width: 80px;
                            height: 100%;
                            padding-left: 10px;
                            padding-right: 20px;
                            box-sizing: border-box;

                            label {
                                position: relative;
                                cursor: pointer;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    left: -30px;
                                    @include sys-radius-btn(20px, 20px, #F8FAFE);
                                    border: 2px solid #BCC3D8;
                                    transform: translateY(-50%);
                                    box-sizing: border-box;
                                }
                            }

                            input:checked+label::before {
                                background: url(../assets/img/yes.png) no-repeat center center/contain;
                                border: none;
                            }

                            input {
                                @include sys-radius-btn(20px, 20px, #F8FAFE);
                                border: 2px solid #BCC3D8;
                                margin-right: 10px;
                                opacity: 0;

                            }
                        }
                    }
                }

                // .el-form-item {
                //     display: flex;
                //     align-items: center;
                //     width: 325px;
                //     height: 60px;
                //     text-align: left;
                //     .el-form-item__label {
                //         @include sys-font(20px, #000);    
                //         flex: 1;

                //     }
                //     .el-input {
                //         flex: none;

                //     }
                // }
            }
        }
    }
}
</style>